
import { computed, defineComponent, WritableComputedRef } from "vue"

export default defineComponent({
  name: "TextareaForm",
  components: {},
  props: {
    modelValue: {
      type: String,
      required: false,
    },
  },
  // props: ["modelValue"],
  emits: ["update:modelValue"],

  setup: function (props, { emit }) {
    const value: WritableComputedRef<any> = computed({
      get(): string | undefined {
        return props.modelValue
      },
      set(value): void {
        emit("update:modelValue", value)
      },
    })

    return {
      value,
    }
  },
})
