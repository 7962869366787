
import { computed, defineComponent, onMounted, reactive, ref, WritableComputedRef } from "vue"
import { productsState, newProduct, createProduct } from "@/composables/useProducts"
import { Product } from "@/types"
import AdminItemForm from "@/components/admin/AdminItemForm.vue"
import AdminButton from "@/components/admin/AdminButton.vue"
import AdminDialog from "@/components/admin/AdminDialog.vue"
import router from "@/router"

interface ComponentState {
  product: Product | null
}

export default defineComponent({
  name: "adminProductCreate",
  components: {
    AdminItemForm,
    AdminButton,
    AdminDialog,
  },
  setup: function () {
    const state = reactive<ComponentState>({
      product: null,
    })

    const agreementDialog = ref<boolean>(false)

    onMounted(() => {
      newProduct()
      state.product = productsState.product
    })

    const onSave = () => {
      createProduct(state.product!)
    }

    const anyLoading: WritableComputedRef<boolean> = computed(() => productsState.loading)

    const onAgree = () => {
      router.push({ name: "AdminProductList" })
    }

    const onCancel = () => {
      agreementDialog.value = false
    }

    return {
      state,
      anyLoading,
      agreementDialog,
      onSave,
      onAgree,
      onCancel,
    }
  },
})
