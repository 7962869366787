
import { defineComponent, computed, WritableComputedRef } from "vue"
import { Product } from "@/types"
import InputForm from "@/components/global/InputForm.vue"
import TextareaForm from "@/components/global/TextareaForm.vue"

export default defineComponent({
  name: "adminItemForm",

  components: {
    TextareaForm,
    InputForm,
  },

  props: {
    modelValue: {
      type: Object as () => Product | null,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  setup: function (props, { emit }) {
    const product: WritableComputedRef<Product | null> = computed({
      get(): Product | null {
        return props.modelValue
      },
      set(): void {
        emit("update:modelValue", product)
      },
    })

    const anyLoading: WritableComputedRef<boolean> = computed(() => props.loading || !product.value)

    return {
      product,
      anyLoading,
    }
  },
})
