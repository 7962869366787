
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  ref,
  WritableComputedRef,
} from "vue"
import {
  productsState,
  getAndSetProduct,
  deleteProduct,
  updateProduct,
} from "@/composables/useProducts"
import AdminItemForm from "@/components/admin/AdminItemForm.vue"
import { Product } from "@/types"
import AdminButton from "@/components/admin/AdminButton.vue"
import AdminDialog from "@/components/admin/AdminDialog.vue"
import { getAndSetProductReviews, reviewsState } from "@/composables/useReviews"
import router from "@/router"

interface ComponentState {
  product: Product | null
  productToEdit: Product | null
}

export default defineComponent({
  name: "AdminItemEdit",
  components: {
    AdminDialog,
    AdminButton,
    AdminItemForm,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup: function (props) {
    const state = reactive<ComponentState>({
      product: null,
      productToEdit: null,
    })
    const agreementDialog = ref<boolean>(false)
    const agreementDialogDeleteComplete = ref<boolean>(false)

    onMounted(() => {
      setProductInForm()
      getAndSetProductReviews(props.id)
    })

    const setProductInForm = async (): Promise<void> => {
      await getAndSetProduct(props.id)

      state.product = productsState.product
      state.productToEdit = Object.assign({}, productsState.product)
    }

    const onItemSave = async (product: Product): Promise<void> => {
      await updateProduct(props.id, product)
    }

    const onItemDelete = async (): Promise<void> => {
      await deleteProduct(props.id)
      agreementDialog.value = false
      agreementDialogDeleteComplete.value = true
    }
    const onCancel = () => {
      agreementDialog.value = false
    }
    const onItemReset = () => {
      state.productToEdit = state.product
    }

    const onDeleteComplete = () => {
      router.push({ name: "AdminProductList" })
    }

    const anyLoading: WritableComputedRef<boolean> = computed(() => productsState.loading)

    return {
      state,
      anyLoading,
      onItemSave,
      onItemDelete,
      onDeleteComplete,
      onItemReset,
      agreementDialog,
      agreementDialogDeleteComplete,
      onCancel,
      reviews: computed(() => reviewsState.reviews),
      reviewsLoading: computed(() => reviewsState.loading),
    }
  },
})
